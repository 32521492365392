// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files
import React from 'react';
import { kebabCase } from 'lodash';
import { Link, graphql } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';

import withLayout from '../layout';
import SEO from "../components/seo"


const TagsPage = (props) => {
  const allTags = props.data.allMarkdownRemark.group;
  const { intl } = props;
  const titleText = intl.formatMessage({id: 'blog.tags-title'});

  return (
    <div>
      <SEO title={titleText} />
      <div>
        <h1><FormattedMessage id="blog.tags-title" /></h1>
        <ul>
          {allTags.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


const customProps = {
    localeKey: 'tags',
};

export default withLayout(customProps)(injectIntl(TagsPage));

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
